import {PrimeVueEvent} from "@/model/common/PrimeVueTypes";
import moment from "moment";
import {FilterMatchMode} from "primevue/api";
import {UserType} from "@/model/Entity";

function validatePeriodFilter(p) {
    return Array.isArray(p) &&
        p.length === 2 &&
        p.every(x => x);
}

export function cleanParams(event: PrimeVueEvent, userType: UserType | UserType[]) {
    const params: PrimeVueEvent = event
        ? JSON.parse(JSON.stringify(event))
        : null;

    if (params && validatePeriodFilter(params?.filters?.period?.value)) {
        const [from, to] = params.filters.period.value;

        params.filters.date_from_at = {
            value: moment(from).format('YYYY-MM-DD'),
            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
        };

        params.filters.date_to_at = {
            value: moment(to).format('YYYY-MM-DD'),
            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
        };
    }

    params?.filters.period && delete params.filters.period;

    return {
        page: params?.page || 0,
        per_page: params?.rows || 10,

        filters: {
            entity_type: {
                value: userType,
                matchMode: Array.isArray(userType)
                    ? FilterMatchMode.IN
                    : FilterMatchMode.EQUALS
            },
            ...(params?.filters || {})
        }
    }
}
