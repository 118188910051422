import { PrimeVueEvent } from "@/model/common/PrimeVueTypes";
import { UserType } from "@/model/Entity";
import { PaginatedResponse } from "@/model/PaginatedResponse";
import { UnprocessedEntity } from "@/model/Report";
import { useMessage } from "@plugins/Messages-plugin";
import { reportsService } from "@services/reports.service";
import { zonesService } from "@services/zones.service";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { BillingRoutesEnum } from "../router";

export function UnprocessedEntities(userType: UserType) {
  const router           = useRouter();
  const { errorMessage } = useMessage();
  const isLoading        = ref<boolean>(false);

  // Filtri
  const filters = ref({
    from_to: [],
    zone: null
  })

  async function restoreFilters() {
    const {
      date_from_at,
      date_to_at,
      zone_id
    } = router.currentRoute.value.query;

    if (zone_id) {
      const z = await zonesService.getById(+zone_id)
      filters.value.zone = z;
    }

    if (date_from_at && date_to_at) {
      filters.value.from_to = [date_from_at, date_to_at];
    }
  }

  function onFiltersSubmit(data) {
    if (data.from_to?.length !== 2) {
      errorMessage('Inserire un intervallo di date');
      return;
    }

    filters.value = data;
    updateRoute(data);
    loadData();
  }

  function updateRoute(query) {
    router.replace({
      name: userType === UserType.CUSTOMER
        ? BillingRoutesEnum.BILLING_CUSTOMERS_FIND
        : BillingRoutesEnum.BILLING_SUPPLIERS_FIND,
      query
    });
  }

  // Tabella
  const response     = ref<PaginatedResponse<UnprocessedEntity>>(null);
  const values       = computed(() => response.value?.data);
  const totalRecords = computed(() => response.value?.total);

  function getRoute(data) {
    const [from, to] = filters.value.from_to;

    const params: any = {
      from, to,
      entityId: data.id,
      zoneId: data.zone_id
    }

    return {
      name: userType === UserType.CUSTOMER
        ? BillingRoutesEnum.BILLING_CUSTOMERS_NEW
        : BillingRoutesEnum.BILLING_SUPPLIERS_NEW,
      params
    }
  }

  // Caricamento dati
  function getParams(e?: PrimeVueEvent) {
    const [date_from_at, date_to_at] = filters.value.from_to;

    return {
      page: e?.page ?? 1,
      per_page: e?.rows ?? 10,
      date_from_at,
      date_to_at,
      zone_id: filters.value.zone?.id,
    }
  }

  async function loadData(e?: PrimeVueEvent) {
    try {
      isLoading.value = true;

      const p = getParams(e);

      updateRoute(p)

      if ( userType === UserType.CUSTOMER ) {
        response.value = await reportsService.unprocessedCustomers(p);
      } else {
        response.value = await reportsService.unprocessedSuppliers(p);
      }
    } catch (error) {
      errorMessage("Caricamento non riuscito")
    } finally {
      isLoading.value = false;
    }
  }

  onMounted(async () => {
    if (router.currentRoute.value.query?.date_from_at) {
      isLoading.value = true;
      await restoreFilters();
    }

    loadData();
  })

  return {
    backRoute: {
      name: userType === UserType.CUSTOMER
        ? BillingRoutesEnum.BILLING_CUSTOMERS
        : BillingRoutesEnum.BILLING_SUPPLIERS,
    },
    isLoading,
    // Filtri
    filters,
    onFiltersSubmit,

    // Tabella
    values,
    totalRecords,
    loadData,

    // Selezione
    getRoute,
  }
}
