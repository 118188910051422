const dateFormat = {
  mounted(el) {
    const date = new Date(el.innerText);

    if(date) {
      const formatter = Intl.DateTimeFormat(undefined, 
        {
          month: '2-digit',
          day: '2-digit',
          year: "numeric"
        });

      el.innerText = formatter.format(date);
    }
  }
}

export { dateFormat };