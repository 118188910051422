
import { ZoneAutocomplete }                              from "@/components";
import moment                                            from 'moment';
import { defineComponent, onMounted, ref, toRef, watch } from 'vue';

export default defineComponent( {
  components: {
    ZoneAutocomplete,
  },
  props     : {
    isLoading : {
      type   : Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
    }
  },
  setup(p, { emit }) {
    const modelValue = toRef(p, 'modelValue');
    const zone       = ref( null );
    const dateRange  = ref<[ Date, Date ]>( lastMonth() );

    function lastMonth() {
      return [
        moment().subtract( 1, 'month' ).startOf( 'month' ).toDate(),
        moment().subtract( 1, 'month' ).endOf( 'month' ).toDate(),
      ] as [ Date, Date ]
    }

    function dateRangeString() {
      if ( !dateRange.value || dateRange.value?.some(x => !x) ) {
        return;
      }

      return [
        moment((dateRange.value[0] as Date)).format('YYYY-MM-DD'),
        moment((dateRange.value[1] as Date)).format('YYYY-MM-DD'),
      ]
    }

    function submit() {
      emit('submit', {
        from_to: dateRangeString(),
        zone: zone.value
      });
    }

    watch( modelValue, () => {
      dateRange.value = modelValue.value.from_to?.length === 2
          ? [
            moment( modelValue.value.from_to[0] ).toDate(),
            moment( modelValue.value.from_to[1] ).toDate()
          ]
          : lastMonth();

      zone.value = modelValue.value?.zone;
    }, { deep: true } )

    onMounted(() => {
      emit('update:modelValue', {
        from_to: dateRangeString(),
        zone: zone.value
      })
    });

    return {
      submit,
      zone,
      dateRange,
    }
  }
})
