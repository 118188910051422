import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_date_format = _resolveDirective("date-format")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    lazy: true,
    loading: _ctx.isLoading,
    value: _ctx.value,
    rows: 10,
    paginator: true,
    "rows-per-page-options": [10, 20, 50, 100],
    totalRecords: _ctx.totalRecords,
    stripedRows: "",
    responsiveLayout: "scroll",
    onPage: _ctx.onPage
  }, {
    empty: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('billing_supplier.table.empty_message')), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        header: "ID",
        field: "id"
      }, {
        body: _withCtx(({data: { id }}) => [
          _createVNode(_component_router_link, {
            to: _ctx.orderRoute(id),
            class: "dark-link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(id), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        header: "Indirizzo di consegna",
        field: "pickup_full_address"
      }),
      _createVNode(_component_Column, {
        header: "Indirizzo di consegna",
        field: "dropoff_full_address"
      }),
      _createVNode(_component_Column, {
        header: "Data esecuzione",
        field: "order_date"
      }, {
        body: _withCtx(({data: {order_date}}) => [
          _withDirectives(_createElementVNode("span", null, [
            _createTextVNode(_toDisplayString(order_date), 1)
          ], 512), [
            [_directive_date_format]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        header: "Stato finale",
        headerClass: "column-header-center",
        field: "status"
      }, {
        body: _withCtx(({data: { status }}) => [
          _createElementVNode("div", {
            class: _normalizeClass(`order-badge status-${status}`)
          }, _toDisplayString(_ctx.$t(`order.status_${status}`)), 3)
        ]),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "cols")
    ]),
    _: 3
  }, 8, ["loading", "value", "totalRecords", "onPage"]))
}