import {UserType} from "@/model/Entity";
import {PrimeVueEvent} from "@/model/common/PrimeVueTypes";
import {reportsService} from "@services/reports.service";
import {computed, ref} from "vue";
import {PaginatedResponse} from "@/model/PaginatedResponse";
import {Report} from "@/model/Report";
import {useMessage} from "@plugins/Messages-plugin";
import {cleanParams} from "@/modules/billing/logic/cleanParams";

export function formerList(userType: UserType | UserType[]) {
    const {errorMessage} = useMessage();

    const isLoading = ref<boolean>(false);

    const response = ref<PaginatedResponse<Report>>(null)
    const reports = computed(() => response.value?.data);
    const totalRecords = computed(() => response.value?.total);

    async function loadReports(event?: PrimeVueEvent) {
        try {
            isLoading.value = true;

            response.value = await reportsService.index(
                cleanParams(event, userType)
            );
        } catch (error) {
            console.error(error)
            errorMessage("Caricamento reports non riuscito")
        } finally {
            isLoading.value = false;
        }
    }

    return {
        isLoading,
        loadReports,
        reports,
        totalRecords,
    }
}
