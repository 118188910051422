
import { defineComponent, onMounted, ref } from "vue";
import { useI18n }                         from "vue-i18n";
import { CurrencyText }                    from "@sharedComponents";
import { dateFormat }                      from "@/directives";

export default defineComponent( {
  inheritAttrs: false,
  components  : {
    CurrencyText,
  },

  directives: {
    dateFormat
  },

  props: {
    isLoading   : {
      type   : Boolean,
      default: false,
    },
    totalRecords: {
      type: Number
    },
    reports     : {
      type: Array
    }
  },

  setup( _, { emit } ) {
    const table = ref();
    const { t } = useI18n();

    function onPage( e ) {
      emit( 'update', e )
    }

    function onFilter( e ) {
      emit( 'update', e )
    }

    const billingTypeOptions = [
      { label: t( 'billing_customers.type_10' ), value: 10 },
      { label: t( 'billing_customers.type_20' ), value: 20 },
      { label: t( 'billing_customers.type_15' ), value: 15 },
    ]

    function validatePeriodFilter( p ) {
      return (
          Array.isArray( p ) &&
          p.length === 2 &&
          p.every( x => x )
      )
    }

    function updatePeriod( value: any, filterCallback: Function ) {
      if (validatePeriodFilter( value )) {
        filterCallback();
      }
    }

    function onExportClick() {
      const e = table.value.createLazyLoadEvent();
      delete e.originalEvent;
      emit( 'export', e )
    }

    onMounted( () => {
      const e = table.value.createLazyLoadEvent();
      delete e.originalEvent;
      emit( 'update', e );
    } );

    return {
      table,
      billingTypeOptions,
      updatePeriod,
      onPage,
      onFilter,
      onExportClick
    }
  }
} )
