
import { defineComponent }  from "vue";
import { PrimeVueEvent }    from "@/model/common/PrimeVueTypes";
import { OrdersRoutesEnum } from "@/modules/orders/router";
import { dateFormat }       from "@/directives";

export default defineComponent( {
  directives: { dateFormat },

  props: {
    isLoading   : {
      type   : Boolean,
      default: false
    },
    value       : {
      type   : Array,
      default: () => []
    },
    totalRecords: {
      type   : Number,
      default: 0
    }
  },

  setup( _, { emit } ) {
    function onPage( e: PrimeVueEvent ) {
      emit( 'page', e )
    }

    function orderRoute( id: number ) {
      return {
        name  : OrdersRoutesEnum.ORDERS_DETAIL,
        params: {
          orderId: id
        }
      }
    }

    return {
      orderRoute,
      onPage
    }
  }
} )
