import {useMessage} from "@plugins/Messages-plugin";
import {ref} from "vue";
import {PrimeVueEvent} from "@/model/common/PrimeVueTypes";
import {reportsService} from "@services/reports.service";
import moment from "moment";
import {fileDownload} from "@sharedUtils/utils";
import {cleanParams} from "@/modules/billing/logic/cleanParams";
import {UserType} from "@/model/Entity";

export function useExport(userType: UserType|UserType[]) {
    const { errorMessage } = useMessage();

    const exportInProgress = ref(false);

    async function onExport(params: PrimeVueEvent) {

        if (params.filters.period?.value?.length !== 2) {
            errorMessage('Inserire un periodo valido');
            return;
        }

        try {
            exportInProgress.value = true;

            const response = await reportsService.export(
                cleanParams(params,userType)
            );

            const period = params.filters.period?.value
                ?.map(d => moment(d).format('YYYYMMDD'))
                .join('_');

            fileDownload(
                `fatture_${period}.xlsx`,
                response,
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
        } catch (e) {
            console.error(e);
            errorMessage("Export non riuscito");
        } finally {
            exportInProgress.value = false;
        }
    }

    return {
        exportInProgress,
        onExport
    }
}
