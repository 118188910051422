
import { PrimeVueEvent } from "@/model/common/PrimeVueTypes";
import { defineComponent } from "@vue/runtime-core";

import { CurrencyText } from "@sharedComponents";

export default defineComponent({
  components: {
    CurrencyText
  },

  props: {
    isLoading: {
      type: Boolean,
    },
    value: {
      type: Array,
    },
    totalRecords: {
      type: Number,
    },
    getRoute: {
      type: Function,
    },
    displayZoneColumn: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { emit }) {
    function onPage(params: PrimeVueEvent) {
      params?.originalEvent && delete params.originalEvent;
      emit('update', params)
    }

    return {
      onPage
    }
  }
})
