import { PaginatedResponse } from '@/model/PaginatedResponse';
import {
    Report, UnprocessedCustomer, UnprocessedOrder, UnprocessedOrdersKpi, UnprocessedSupplier
}                            from '@/model/Report';
import {
    Shift
}                            from '@/model/Shift';
import { CRUD }              from './base/crud';

interface PaginationParams {
    page: number;
    per_page: number;
}

export interface UnprocessedEntitiesParams {
    date_from_at: string;
    date_to_at: string;
    zone_id?: number;
}

export interface UnprocessedOrdersParams
    extends UnprocessedEntitiesParams, PaginationParams {
    entity_id: number;
}

type KpiParams = Omit<UnprocessedOrdersParams, keyof PaginationParams>;

export interface ProcessOrdersBody {
    entity_id: number;
    date_from_at: string;
    date_to_at: string;
    zone_id?: number;
    total_amount: number;
    total_shifts_amount?: number;
    note: string;
}

export interface SupplierShift {
    weekday_range1_shifts_qty: number;
    weekday_range1_cost: number;

    weekday_range2_shifts_qty: number;
    weekday_range2_cost: number;

    weekday_range3_shifts_qty: number;
    weekday_range3_cost: number;

    shift: Shift;
}

class ReportsService extends CRUD<Report, any> {
    readonly endPoint = `admin/report-bill`;

    unprocessedCustomers(params: UnprocessedEntitiesParams) {
        return this.get<PaginatedResponse<UnprocessedCustomer>>(
            `${this.endPoint}/unprocessed-customers`,
            {params}
        )
    }

    unprocessedSuppliers(params: UnprocessedEntitiesParams) {
        return this.get<PaginatedResponse<UnprocessedSupplier>>(
            `${this.endPoint}/unprocessed-suppliers`,
            {params}
        )
    }

    unprocessedOrders(params: UnprocessedOrdersParams) {
        return this.get<PaginatedResponse<UnprocessedOrder>>(
            `${this.endPoint}/unprocessed-orders`,
            {params}
        )
    }

    kpi(params: KpiParams) {
        return this.get<UnprocessedOrdersKpi>(
            `${ this.endPoint }/unprocessed-orders/kpi`,
            { params }
        )
    }

    processOrders( body: ProcessOrdersBody ) {
        return this.post<any>(
            `${ this.endPoint }/process-orders`,
            body
        )
    }

    updateReport( id: number, body: { total_amount: number, note: string } ) {
        return this.patch<Report>( `${ this.endPoint }/${ id }`, body )
    }

    getSupplierShifts( supplierId: number, params: UnprocessedEntitiesParams ) {
        return this.get<SupplierShift[]>(
            `${ this.endPoint }/unprocessed-suppliers/${ supplierId }/shifts`,
            { params }
        )
    }

    public export( args ): Promise<string> {
        return this.get<string>(`${this.endPoint}/export`,
            {
                params: this.getCleanParams(args),
                responseType: 'blob',
            }
        )
    }
}

export const reportsService = new ReportsService();
